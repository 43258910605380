<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="server.connection"
          >Server Views: {{ server.connection.database !== null ? server.connection.database.toUpperCase() : '-' }}</v-toolbar-title
        >
        <v-spacer />
        <v-toolbar-items>
          <v-btn dark text @click="addViewField = true">
            Adicionar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- views -->
      <v-container v-if="server.configuration">
        <div v-for="(value, key) in server.configuration.views" :key="key" class="box">
          <v-card-title class="pa-0">{{ key }}</v-card-title>
          <v-switch v-model="server.configuration.views[key].inherit" class="my-2" hide-details color="primary" label="Herdar do ERP" />
          <v-spacer />
          <div class="my-4">
            <v-btn outlined color="error" class="mr-2" small @click="openChangesDelete(key)">Deletar View</v-btn>
            <v-btn outlined color="primary" class="mr-2" small @click="openChangesEdit(key, value)">Atualizar View</v-btn>
          </div>
          <sql-builder :show="server.configuration.views[key].inherit" :sql.sync="server.configuration.views[key]" class="mb-5" itemscope />
          <v-divider />
        </div>
        <change-dialog v-if="changesDialogEdit" :dialog.sync="changesDialogEdit" :message.sync="changesMessage" @proceed="updateView(key, value)" />
        <change-dialog v-if="changesDialogDelete" :dialog.sync="changesDialogDelete" :message.sync="changesMessage" @proceed="removeView(key)" />
      </v-container>
    </v-card>

    <!-- add view modal -->
    <v-dialog v-if="server.configuration" v-model="addViewField" width="800">
      <v-card class="pa-4">
        <v-card-title class="headline"><v-icon class="mb-2 mr-2">mdi-database-cog-outline</v-icon> Adicionar View</v-card-title>
        <v-card-text>
          <v-text-field v-model="sqlKey" solo-inverted placeholder="Nome" />
          <v-switch v-model="inherit" hide-details color="primary" label="Inherit" class="mb-4" />
          <sql-builder :show="inherit" :sql.sync="sql" class="mb-5" itemscope />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn width="240" outlined color="error" @click="addViewField = false"><v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn>
          <v-btn width="240" color="success" @click="saveInsertManager"><v-icon class="mr-1">mdi-content-save</v-icon>Salvar</v-btn>
        </v-card-actions>
        <change-dialog v-if="changesDialogInsert" :dialog.sync="changesDialogInsert" :message.sync="changesMessage" @proceed="addField" />
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { MUTATION_REMOVE_VIEW, MUTATE_ADD_VIEW, MUTATE_EDIT_VIEW } from '@/modules/accounts/graphql'

export default {
  components: {
    SqlBuilder: () => import('@/components/base/SqlBuilder.vue'),
    ChangeDialog: () => import('@/components/base/ChangeDialog')
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    server: {
      type: Object,
      default: () => ({})
    },
    accountId: {
      type: String,
      default: ''
    },
    isDeploying: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    addViewField: false,
    sqlKey: '',
    key: null,
    value: null,
    inherit: false,
    sql: {
      first_value: true,
      atemporal: true,
      query: '',
      date_field: '',
      date_filter_format: '',
      local_date_parser: '',
      realtime: ''
    },
    changesDialogEdit: false,
    changesDialogDelete: false,
    changesDialogInsert: false,
    changesMessage: ''
  }),
  methods: {
    async removeView(key) {
      this.$setLogMessage(this.changesMessage)
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_REMOVE_VIEW,
          variables: {
            id: this.server.accountId,
            index: this.server.index,
            key: key.toString()
          }
        })
        delete this.server.configuration.views[key]
        this.$emit('update:server', this.server)
        this.cleanFields()
        this.close()
        this.$snackbar({ message: `View "${key}" removida` })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao remover view' })
      }
    },
    async addField() {
      this.$setLogMessage(this.changesMessage)
      const queryName = this.sqlKey
      const newSql = Object.assign(
        {},
        { inherit: this.inherit },
        !this.inherit ? { first_value: this.sql.first_value } : null,
        !this.inherit ? { atemporal: this.sql.atemporal } : null,
        !this.inherit ? { query: this.sql.query } : null,
        !this.sql.atemporal ? { date_field: this.sql.date_field } : null,
        !this.sql.atemporal ? { date_filter_format: this.sql.date_filter_format } : null,
        !this.sql.atemporal ? { local_date_parser: this.sql.local_date_parser } : null
      )

      try {
        await this.$apollo.mutate({
          mutation: MUTATE_ADD_VIEW,
          variables: {
            id: this.server.accountId,
            server_index: this.server.index,
            sql: newSql,
            key: queryName
          }
        })
        this.server.configuration.views[queryName] = newSql
        this.addViewField = false
        this.cleanFields()
        this.$snackbar({ message: 'View adicionada' })
        this.close()
      } catch (err) {
        console.log(err)
        this.$snackbar({ message: 'Falha ao adicionar view' })
      }
    },
    async updateView(key, value) {
      this.$setLogMessage(this.changesMessage)
      const variables = Object.assign(
        {},
        { id: this.accountId },
        { serverIndex: this.server.index },
        { key },
        { inherit: value.inherit },
        { atemporal: value.atemporal },
        { realtime: value.realtime },
        !value.inherit && value.first_value !== undefined ? { first_value: value.first_value } : { first_value: true },
        !value.inherit && value.query !== undefined ? { query: value.query } : null,
        !value.atemporal && value.date_field !== undefined ? { date_field: value.date_field } : null,
        !value.atemporal && value.date_filter_format !== undefined ? { date_filter_format: value.date_filter_format } : null,
        !value.atemporal && value.local_date_parser !== undefined ? { local_date_parser: value.local_date_parser } : null
      )

      try {
        await this.$apollo.mutate({
          mutation: MUTATE_EDIT_VIEW,
          variables
        })
        this.cleanFields()
        this.close()
        this.$snackbar({ message: `View "${key}" atualizada`, snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao atualizar view', snackbarColor: '#F44336' })
      }
    },
    openChangesDelete(key) {
      if (this.isDeploying) return this.removeView(key)
      this.changesDialogDelete = true
      this.key = key
    },
    openChangesEdit(key, value) {
      if (this.isDeploying) return this.updateView(key, value)
      this.changesDialogEdit = true
      this.key = key
      this.value = value
    },
    saveInsertManager() {
      if (this.isDeploying) return this.addField()
      this.changesDialogInsert = true
    },
    cleanFields() {
      this.sqlKey = ''
      this.key = null
      this.value = null
      this.inherit = false
      this.sql.first_value = true
      this.sql.atemporal = true
      this.sql.query = ''
      this.sql.date_field = ''
      this.sql.date_filter_format = ''
      this.sql.local_date_parser = ''
    },
    close() {
      this.changesDialogEdit = false
      this.changesDialogDelete = false
      this.changesDialogInsert = false
      return this.$emit('close')
    }
  }
}
</script>
